.bottom {
    width: 100%;
    height: 80px;
    font-size: 12px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.left {
    display: flex;
    margin-right: 50px;
    flex-direction: column;
    position: relative;
}

.right {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: scroll;
    position: relative;
}

.top {
    background-color: white;
    display: flex;
    margin-bottom: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 14px; 
    padding: 20px 20px;
}

.top input {
    border-bottom: 2px solid #000842;
    padding-bottom: 3px;
}

.top img {
    margin-right: 20px;
}

.top > div {
    padding: 10px 0px;
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
}

.user {
    background-color: #E5E5E5;
    padding: 16px;
    display: flex;
    height: 100vh;
    overflow: hidden;
}

.logo {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}