input {
    border: none;
    outline: none;
    width: 100%;
    color: #000;
    font-size: 16px;
    font-weight: 100;
    margin-left: 5px;
}

input::placeholder {
    color: #000;
    font-size: 16px;
    font-weight: 100;
}

.input__wrapper {
    display: flex;
    flex-direction: column;
}

.img_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 2px solid #000842;
    padding-bottom: 10px;
    margin-bottom: 20px;
    align-items: center;
}

.input__label {
    font-size: 13px;
    font-weight: 600;
    color: #999999;
    margin-bottom: 5px;
}

.title {
    font-size: 30px;
    font-weight: 600px;
    color: #000000;
    margin-bottom: 70px;
}

.left {
    width: 400px;
}

button {
    width: 100%;
    background-color: #0C21C1;
    border-radius: 40px;
    border: none;
    color: white;
    font-size: 17px;
    font-weight: 600;
    padding: 10px;
    margin-top: 30px;
}

.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    gap: 100px;
}

.rightImage {
    height: 800px;
}